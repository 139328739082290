<template>
  <v-app-bar app color="secondary" floating :extended="display.mdAndDown.value">
    <v-container fluid>
      <div class="d-flex justify-space-between align-center w-100">
        <router-link to="/">
          <v-img v-if="!display.xs" width="200px" src="@/assets/logo-name.svg" />
          <v-img v-else src="@/assets/logo.svg#svgView(viewBox(0, 0, 67.33, 67))" width="56px" />
        </router-link>

        <v-tooltip location="bottom" :text="$t('actions.start-teamviewer')">
          <template #activator="{ props }">
            <v-btn v-bind="props" variant="text" color="white" :href="'https://quicksupport.me/'" target="__blank">
              <v-icon size="2rem">mdi-teamviewer</v-icon>
              <span>TeamViewer QuickSupport</span>
            </v-btn>
          </template>
        </v-tooltip>

        <v-row v-if="!display.mdAndDown.value" class="text-black flex-grow-0" align="center" justify="end">
          <v-col cols="auto">
            <LanguageSelector />
          </v-col>
          <v-col cols="auto" v-if="authStore.isLoggedIn">
            {{ authStore.user?.email }}
            <v-btn @click="logout"> {{ $t('actions.logout') }} </v-btn>
          </v-col>
        </v-row>
      </div>
    </v-container>

    <template #extension v-if="display.mdAndDown.value">
      <v-container fluid>
        <v-col class="text-black" cols="12">
          <v-row align="center" justify="end">
            <v-col cols="auto">
              <LanguageSelector />
            </v-col>
            <v-col cols="auto" v-if="authStore.isLoggedIn">
              {{ authStore.user?.email }}
              <v-btn @click="logout"> {{ $t('actions.logout') }} </v-btn>
            </v-col>
          </v-row>
        </v-col>
      </v-container>
    </template>
  </v-app-bar>
</template>

<script setup lang="ts">
import { useAuthStore } from '@/store/auth';
import LanguageSelector from './LanguageSelector.vue';
import { useRouter } from 'vue-router';
import { useDisplay } from 'vuetify';

const authStore = useAuthStore();
const router = useRouter();
const display = useDisplay();

async function logout() {
  let cancel: () => void;
  cancel = authStore.$subscribe(async (mut, state) => {
    if (!state.aesticoUserUUID) {
      await router.push('/login');
      cancel();
    }
  });
  authStore.logout();
}
</script>
